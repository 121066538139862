const wvusForgeBaseUrl = process.env.react_app_wvus_forge_base_url ? process.env.react_app_wvus_forge_base_url : 'https://facebookfuncappwvusprod.azurewebsites.net';
const wvusForgeApiKey = process.env.react_app_wvus_forge_api_key ? process.env.react_app_wvus_forge_api_key : 'Yf61TfisfymFmE7a0szh5/63mZJpHG/aGa/dMttoL3yUpchlQvC5CQ==';

export async function getFBGroups(userId, accessToken) {
  try {
    const response = await fetch(
      `${wvusForgeBaseUrl}/api/groups?userId=${userId}&code=${wvusForgeApiKey}`,

    {
        method: "GET",
        headers: {
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      }
    );

    const fbGroupJson = await response.json(); 
    
    return fbGroupJson;
  }
  catch (error) {
    return Promise.reject(error);
  }
}


export async function saveFBGroup(fbGroup, fbUser) {
    try {

    const groupData = {
      "GroupId": fbGroup.GroupId ? fbGroup.GroupId : '',
      "GroupName": fbGroup.GroupName ? fbGroup.GroupName : '',
      "GroupUrl": fbGroup.GroupUrl ? fbGroup.GroupUrl : '',
      "UserFacebookId": fbUser.id ? fbUser.id : '',
      "UserFirstName": fbUser.name ? fbUser.first_name : '',
      "UserLastName": fbUser.name ? fbUser.last_name : '',
      "UserFacebookEmail": fbUser.email ? fbUser.email : ''
    };

      const response = await fetch(
        `${wvusForgeBaseUrl}/api/groups/${fbGroup.GroupId}?code=${wvusForgeApiKey}`,
        {
          method: "PUT",
          headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${fbUser.accessToken}`
  
        },
        body: JSON.stringify(groupData)
      }
    );

    const fbGroupJson = await response.json(); 
    
    return fbGroupJson;
  }
  catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteFBToken(fbGroup, fbUser) {
  try {

      await fetch(
        `${wvusForgeBaseUrl}/api/groups?groupId=${fbGroup.GroupId}&userId=${fbUser.id}&code=${wvusForgeApiKey}`,
        {
          method: "DELETE",
          headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${fbUser.accessToken}`

        }
      }
    );
 
    return;
  }
  catch (error) {
    return Promise.reject(error);
  }
}

export async function testFBGroup(fbGroup, fbUser, photoUrl, caption) {
  
    try {

    const testGroupData = {
      "Url": photoUrl,
      "Caption": caption
    }

    const fbAccessToken = fbUser.accessToken;
    const response = await fetch(
        `${wvusForgeBaseUrl}/api/groups/${fbGroup.GroupId}/photos?code=${wvusForgeApiKey}`,
    {
        method: "POST",
        headers: {
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fbAccessToken}`

        },
        body: JSON.stringify(testGroupData)
      }
    );

    const fbGroupJson = await response.json(); 
    
    return fbGroupJson;
  }
  catch (error) {
    return Promise.reject(error);
  }
}