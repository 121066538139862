import React from "react";
import { Container, Image, Button } from "react-bootstrap";
import { logout, reLogin } from "../services/FBServices";
import { fbAccount } from "../services/FBServices";

const LogoutButton = (  {logoutDisabled}  ) => {
  const mePic = fbAccount?.accountValue?.picture?.data?.url;
  const hasMissingScopes = fbAccount.accountValue?.hasMissingScopes;

  const logoutHandler = () => {
    if (hasMissingScopes) {
      reLogin();
    } else {
      logout();
    }
  };

  return (
    <Container>
      <Image src={`${mePic}`} rounded />
      <Button
        disabled={!hasMissingScopes ? logoutDisabled : false}
        onClick={logoutHandler}
        variant="outline-primary"
        className="btn btn-ghost-orange"
      >
        {hasMissingScopes ? "Re-Login" : "Log Out"}
      </Button>
      
    </Container>
  );
};

export default LogoutButton;
