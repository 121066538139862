import React from "react";
import { useState } from "react";
import TestButton from "./testButton";
import { formatDate_mmddyy } from "../helpers/helper";
import { deleteFBToken, testFBGroup } from "../services/GroupServices";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const Group = ({ fbUser, fbGroup, fbGroups, index, setGroups, setLogoutDisabled, saveFBGroupOnClickHandler }) => {
  const isReadyClass = fbGroup.IsReady
    ? "grouptable__cell--ready"
    : "grouptable__cell--notready";
  const rowClass = index % 2 ? "grouptable__row--even" : "grouptable__row--odd";
  const fbLink = `https://www.facebook.com/groups/${fbGroup.GroupId}/`;
  const STATUS_SUCCESS = "success";
  const STATUS_ERROR = "error";
  const [status, setStatus] = useState("");

  const [saveDisabled, setSaveDisabled] = useState(true);
  const [testDisabled, setTestDisabled] = useState(false);


  const testFBGroupOnClickHandler = (fbUser, fbGroup, setLogoutDisabled) => {
    setStatus("");
    setTestDisabled(true);

    let testCaption = "Test Image from Dashboard";
    let testUrl =
      "https://wvusstatic.com/2021/facebook/groups/RW.jpg";

    testFBGroup({ ...fbGroup }, { ...fbUser }, testUrl, testCaption)
      .then((result) => {
        setTestDisabled(false);

        if (result.GroupId) {
          saveFBGroupOnClickHandler(fbUser, fbGroup, setLogoutDisabled);
          setStatus(STATUS_SUCCESS);
          setSaveDisabled(false);
        } else {
          deleteFBToken(fbGroup, fbUser); 
          alert("Test failed! Token will now be deleted.");
          throw new Error("Test failed!");
        }
      })
      .catch((err) => {
        setStatus(STATUS_ERROR);
        setSaveDisabled(true);
      });
  };

  return (
    <React.Fragment>
      <span className={`grouptable__cell ${rowClass}`}>
        <a href={fbLink} target="_blank" rel="noreferrer">
          {" "}
          {fbGroup.GroupName}{" "}
        </a>
      </span>
      <span className={`grouptable__cell ${rowClass} ${isReadyClass}`}>
        {fbGroup.IsReady ? (
          <FontAwesomeIcon icon={faPlusCircle} />
        ) : (
          <FontAwesomeIcon icon={faMinusCircle} />
        )}
      </span>
      <span className={`grouptable__cell ${rowClass}`}>
        {fbGroup.IsUserGroupAdmin ? "Yes" : "No"}
      </span>
      <span className={`grouptable__cell ${rowClass}`}>
        {fbGroup.IsAppInstalledInGroup ? "Yes" : "No"}
      </span>

      <span className={`grouptable__cell ${rowClass}`}>
        <TestButton
          testDisabled={testDisabled}
          fbGroup={fbGroup}
          fbUser={fbUser}
          onClickHandler={testFBGroupOnClickHandler}

          saveDisabled={saveDisabled}
          fbGroups={fbGroups}
          index={index}
          setLogoutDisabled={setLogoutDisabled}
        />
        <br />
        {status === STATUS_SUCCESS ? (
          <span>
            <span style={{ fontWeight: "bold" }}>Success!</span> Your group has
            been successfully saved. To verify, go to:{" "}
            <a href={fbLink} target="_blank" rel="noreferrer">
              {fbGroup.GroupName}
            </a>
          </span>
        ) : status === STATUS_ERROR ? (
          <span>
            <span style={{ fontWeight: "bold" }}>Error!</span> A problem has
            occurred while saving your group, please try again.
          </span>
        ) : (
          ""
        )}
      </span>
    
      <span className={`grouptable__cell ${rowClass}`}>
        {fbGroup.AccessExpireDate != null
          ? formatDate_mmddyy(fbGroup.AccessExpireDate)
          : "None"}
      </span>
    </React.Fragment>
  );
};

export default Group;
