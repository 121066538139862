import React from "react";

const tvaLink = process.env.react_app_wvus_forge_base_url.includes('test') ? 'https://tvapreprod.worldvision.org/' : 'https://tva.worldvision.org/';


const Footer = () => (
  <footer className="bg-light p-4 text-center">
    <p><a href={tvaLink} target="_blank" rel="noopener noreferrer">Looking for the TVA?</a></p>
    <p>© {(new Date().getFullYear())} World Vision, Inc. All rights reserved.</p>
  </footer>
);

export default Footer;
