import Group from "./Group";


const ListAccounts = ( {fbAccount, accountName, accountID, hasMissingScopes, groups, setLogoutDisabled, saveFBGroupOnClickHandler} ) => {
 
  return (
    <div className="text-center list-accounts">
      <p>
        <strong>FB User:</strong> {`${accountName}`}
      </p>
      <p>
        <strong>FB Account ID:</strong> {`${accountID}`}
      </p>
      <div>
        {hasMissingScopes ? (
          <div className="alert alert-danger" role="alert">
            <i className="fa fa-times-circle" aria-hidden="true"></i> Required
            permissions are missing, please re-login.
          </div>
        ) : (
          ""
        )}
        <div className="grouptable">
          <span className="grouptable_header">Name</span>
          <span className="grouptable_header">Status</span>
          <span className="grouptable_header">Admin?</span>
          <span className="grouptable_header">App Installed?</span>
          <span className="grouptable_header">Test & Save</span>
          <span className="grouptable_header">Token Expiry</span>

          {Array.isArray(groups)
            ? groups.map((group, index) => (
                <Group
                  key={`${index}_group`}
                  fbUser={fbAccount.accountValue}
                  fbGroup={group}
                  fbGroups={groups}
                  index={index}
                  setLogoutDisabled={setLogoutDisabled}
                  saveFBGroupOnClickHandler={saveFBGroupOnClickHandler}
                />
              ))
            : "No groups found."}
        </div>
      </div>
    </div>
  );
};

export default ListAccounts;
