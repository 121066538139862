export function formatDate_mmddyy(dateStr) {

    const date = new Date(dateStr);
  
    let dd = date.getDate();
    let mm = date.getMonth()+1; //January is 0!
    let yy = date.getFullYear().toString().substr(-2);
    
    if(dd < 10) {
      dd = '0' + dd;
    }
    
    if(mm < 10) {
      mm = '0' + mm;
    }
    
    return mm + '/' + dd + '/' + yy;
  } 

  export function readCookie(cookieName) {
    var cookieValue = document.cookie.match("(^|;)\\s*" + cookieName + "\\s*=\\s*([^;]+)");
    return cookieValue ? cookieValue.pop() : "";
  }

  export function setCookie(cookieName, cookieValue, expDays=1) {
    let date = new Date();
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = cookieName + "=" + cookieValue + "; " + expires + "; path=/";
  }