import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";

import LogoutButton from "./logoutButton";
import LoginButton from "./loginButton";
import WVlogo from "../svg/logo.svg";
import { fbAccount } from "../services/FBServices";


const AuthNav = ({logoutDisabled}) => {

  const [account, setAccount] = useState(null);
  useEffect(() => {
    fbAccount.account.subscribe((x) => setAccount(x));
  }, []);

  return (
    <Nav className="justify-content-end">
      {account ? <LogoutButton logoutDisabled={logoutDisabled}/> : <LoginButton />}
    </Nav>
  );
};

const NavBar = ({logoutDisabled}) => {
   return (
    <React.Fragment>
      <Navbar bg="light" expand="md">
        <Container>
          <img src={WVlogo} className="App-logo" alt="logo" />
          <AuthNav logoutDisabled={logoutDisabled}/>
        </Container>
      </Navbar>
      {logoutDisabled ? (
        <p className="navbar__message--warning">If you logout, your credentials will fail when posting to Facebook.</p>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default NavBar;
