import React from "react";

const TestButton = ({ testDisabled, fbUser, fbGroup, setLogoutDisabled, onClickHandler }) => {
  return (
    <button
      disabled={testDisabled}
      className="btn btn-small btn-primary btn-fb-group-action"
      onClick={() => onClickHandler(fbUser, fbGroup, setLogoutDisabled)}
    >
      test & save
    </button>
  );
};

export default TestButton;
