import React from "react";
import { Button } from "react-bootstrap";

import { login } from "../services/FBServices";

const loginHandler = () => {
  // TODO: Add Context to `display:block` the main screen
  login();
};

const LoginButton = () => {
  return (
    <Button onClick={loginHandler} variant="primary" className="btn-primary">
      Log In
    </Button>
  );
};

export default LoginButton;
