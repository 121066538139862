import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { NavBar, Footer, Home } from "./components";
import "./App.css";
import { readCookie, setCookie } from "./helpers/helper";
import { fbAccount } from "./services/FBServices";
import { getFBGroups, saveFBGroup } from "./services/GroupServices";

const App = () => {
  const [account, setAccount] = useState(null);
  const accountName = fbAccount.accountValue?.name;
  const accountID = fbAccount.accountValue?.id;
  const hasMissingScopes = fbAccount.accountValue?.hasMissingScopes;
  const accessToken = fbAccount.accountValue?.accessToken;
  const [groups, setGroups] = useState([]);
  const [logoutDisabled, setLogoutDisabled] = useState(false);

  useEffect(() => {
    fbAccount.account.subscribe((x) => setAccount(x));
  }, []);

  useEffect(() => {
    getFBGroups(accountID, accessToken).then((groups) => {
      setGroups(groups);
    });
  }, [accountID, accessToken]);

  useEffect(() => {
    if (readCookie("fbDashLogDisabled")) {
      setLogoutDisabled(true);
    }
  }, []);

  const disableLogOut = () => {
    setCookie("fbDashLogDisabled", "true", 30);
    setLogoutDisabled(true);
  };

  const saveFBGroupOnClickHandler = (fbUser, fbGroup, setLogoutDisabled) => {
    disableLogOut(setLogoutDisabled);

    saveFBGroup({ ...fbGroup }, { ...fbUser })
      .then((result) => {
        if (result.IsUserAccessTokenStored) {
          getFBGroups(accountID, fbUser.accessToken).then((groups) => {
            setGroups(groups);
          });
        } else {
          alert("Unable to save UserAccessToken.");
        }
      })
      .catch((error) => {
        alert("Unable to save UserAccessToken");
        console.log(error);
      });
  };

  return (
    <div id="app" className="d-flex flex-column h-100">
      <NavBar logoutDisabled={logoutDisabled} />
      <Container className="flex-grow-1 mt-5">
        <Home
          account={account}
          fbAccount={fbAccount}
          accountName={accountName}
          accountID={accountID}
          hasMissingScopes={hasMissingScopes}
          groups={groups}
          setLogoutDisabled={setLogoutDisabled}
          saveFBGroupOnClickHandler={saveFBGroupOnClickHandler}
        />
      </Container>
      <Footer />
    </div>
  );
};

export default App;
