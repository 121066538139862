import React from "react";
import { render } from "react-dom";
import App from "./App";
import { initFacebookSdk } from "./services/FBServices";

// wait for facebook sdk before startup
initFacebookSdk().then(startApp);

function startApp() {
  render(<App />, document.getElementById("root"));
}
