import React, {  Fragment } from "react";
import { Container } from "react-bootstrap";

import { ListAccounts, Hero } from "../components";

const Home = ( {account, fbAccount, accountName, accountID, hasMissingScopes, groups, setLogoutDisabled, saveFBGroupOnClickHandler }) => {

  return (
    <Fragment>
      <Hero />
      {account ? (
        <>
          <ListAccounts fbAccount={fbAccount} accountName={accountName} accountID={accountID} hasMissingScopes={hasMissingScopes} groups={groups}   setLogoutDisabled={setLogoutDisabled} saveFBGroupOnClickHandler={saveFBGroupOnClickHandler} />
        </>
      ) : (
        <Container className="text-center">
          <p>
            <strong>Please Login to continue</strong>.
          </p>
        </Container>
      )}
    </Fragment>
  );
};

export default Home;
