import { BehaviorSubject } from "rxjs";
import { history } from "../helpers/history";

const accountSubject = new BehaviorSubject(null);
export const fbAccount = {
  account: accountSubject.asObservable(),
  get accountValue() {
    return accountSubject.value;
  },
};

const fbAppId = process.env.react_app_fb_admin_dashboard_app_id || 271907367985857;
const fbAppVersion = process.env.react_app_fb_admin_dashboard_sdk_version || "v10.0";

export function initFacebookSdk() {
  return new Promise((resolve) => {
    // wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: fbAppId,
        status: true, 
        cookie: true,
        xfbml: true,
        version: fbAppVersion,
      });

      // auto authenticate with the api if already logged in with facebook
      window.FB.getLoginStatus(({ status, authResponse }) => {
        if (authResponse) {
          setAccount(authResponse).then(resolve);
        } else {
          resolve();
        }
      }, true);
    };


    // load facebook sdk script
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  });
}

const defaultLoginParams = {
  scope: "public_profile,email,publish_to_groups",
  return_scopes: true,
};

export async function setAccount(authResponse) {
  let meResponse = await new Promise(async (resolve) => {
    window.FB.api(
      "/me",
      "GET",
      {
        fields: "id, name, first_name, last_name, email, picture",
      },
      (response) => {
        resolve(response);
      }
    );
  });

  const grantedScopes = authResponse.grantedScopes
    ? authResponse.grantedScopes.split(",")
    : [];
  const requiredScopes = ["public_profile", "email", "publish_to_groups"];

  const missingScopes = requiredScopes.filter(
    (e) => !grantedScopes.includes(e)
  );

  accountSubject.next({
    ...meResponse,
    accessToken: authResponse.accessToken,
    hasMissingScopes: missingScopes.length > 0,
    missingScopes: missingScopes,
  });

  return meResponse;
}

export async function login(params = defaultLoginParams) {
  const { authResponse, status } = await new Promise(async (resolve) => {
    window.FB.login((response) => {
      resolve(response);
    }, params);
  });
  if (status !== "connected") return;

  await setAccount(authResponse);
  history.push("/");
}

export function reLogin() {
  const reLoginParams = {
    auth_type: "rerequest",
    scope: "public_profile,email,publish_to_groups",
    return_scopes: true,
  };
  login(reLoginParams);
}

export function logout() {
  // revoke app permissions to logout completely because FB.logout() doesn't remove FB cookie
  window.FB.api("/me/permissions", "delete", null, () => window.FB.logout());
  accountSubject.next(null);
  history.push("/");
}

//   const { accessToken } = window.FB.getAuthResponse();
